import {createReducer, on} from '@ngrx/store';
import {NextProduktnummer, ProduktEntitiesState} from '../states/produkt-entities.state';
import {produktEntitiesAdapter} from '../adapters/produkt-entities.adapter';
import {ProduktEntitiesActions} from '../actions/produkt-entities.actions';
import {ProduktTableActions} from '../actions/produkt-table.actions';
import {ProduktDialogActions} from '../actions/produkt-dialog.actions';
import {DeleteProduktDialogActions} from '../actions/delete-produkt-dialog.actions';


export const initialProduktEntitiesState: ProduktEntitiesState = produktEntitiesAdapter.getInitialState({
  isLoading: false,
  totalElements: 0,
  createSaveActionSuccessful: false,
  deleteActionSuccessful: false,
});

export const produktEntitiesReducer = createReducer(
  initialProduktEntitiesState,

  on(ProduktTableActions.readProdukte, (state) => ({
    ...state,
    isLoading: true,
  })),

  on(ProduktEntitiesActions.readProdukteSuccess, (state, {produktDtos}) => {
    return produktEntitiesAdapter.upsertMany(
      produktDtos,
      {
        ...state,
        isLoading: false,
      },
    );
  }),

  on(ProduktEntitiesActions.readProdukteFailed, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(
    ProduktEntitiesActions.getProduktByIdSuccess,
    (state, action) => {
      return produktEntitiesAdapter.upsertOne(action.produktDto, state);
    }
  ),

  on(
    ProduktEntitiesActions.readNextProduktnummerSuccess,
    (state, action) => ({
      ...state,
      nextProduktnummer: action.produktnummer,
    })
  ),

  on(ProduktEntitiesActions.countProdukteElementsSuccess, (state, {totalElements}) => ({
    ...state,
    totalElements,
  })),

  on(ProduktEntitiesActions.createProdukt, (state) => ({
    ...state,
    createSaveActionSuccessful: false,
  })),

  on(ProduktEntitiesActions.createProduktSuccess, (state, {produktDto}) => {
    return produktEntitiesAdapter.upsertOne(
      produktDto,
      {
        ...state,
        totalElements: state.totalElements + 1,
        createSaveActionSuccessful: true,
      },
    );
  }),

  on(ProduktEntitiesActions.updateProdukt, (state) => ({
    ...state,
    createSaveActionSuccessful: false,
  })),

  on(ProduktEntitiesActions.updateProduktSuccess, (state, {produktDto}) => {
    return produktEntitiesAdapter.updateOne(
      {
        id: produktDto.id,
        changes: produktDto,
      },
      {
        ...state,
        createSaveActionSuccessful: true,
      }
    );
  }),

  on(ProduktEntitiesActions.deleteProdukt, (state) => ({
    ...state,
    deleteActionSuccessful: false,
  })),

  on(ProduktEntitiesActions.deleteProduktSuccess, (state) => {
    return {
      ...state,
      totalElements: state.totalElements - 1,
      deleteActionSuccessful: true,
    };
  }),

  on(ProduktEntitiesActions.deleteProduktFailure, (state) => ({
    ...state,
    deleteActionSuccessful: false,
  })),

  /**
   * Beim Schließen des Create- / Update-Dialogs wird der Status zurückgesetzt.
   */
  on(ProduktDialogActions.close, (state) => ({
    ...state,
    createSaveActionSuccessful: false,
  })),

  /**
   * Beim Schließen des Delete-Dialogs wird der Status zurückgesetzt.
   */
  on(DeleteProduktDialogActions.close, (state) => ({
    ...state,
    deleteActionSuccessful: false,
  })),

  /**
   * Nächste verfügbare Kundennummer wird gespeichert.
   */
  on(ProduktEntitiesActions.detectNextProduktnummerSuccess, (state, {produkt, betriebId}) => {
    const highestProdukt = produkt.content.at(0);
    const highestProduktnummer = highestProdukt?.nummer;
    const nummer = highestProduktnummer ? highestProduktnummer + 1 : 1;
    const nextProduktnummer: NextProduktnummer = {
      betriebId,
      nummer,
    };
    const nextProduktnummern = state.nextProduktnummern || [];

    /*
     * INFO: Wenn bereits ein Eintrag mit der BetriebId existiert, wird dieser ersetzt.
     * Ansonsten wird ein neuer Eintrag hinzugefügt.
     */
    const newState = {
      ...state,
      nextProduktnummern: [
        ...nextProduktnummern.filter(kn => kn.betriebId !== betriebId),
        nextProduktnummer,
      ],
    };

    if (highestProdukt) {
      return produktEntitiesAdapter.upsertOne(
        highestProdukt,
        newState,
      );
    }
    return newState;
  }),
);

